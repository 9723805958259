import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { Box } from 'native-base';
import { NavigationContainer, useIsFocused } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MessagingHandler, LinkHandler, logAnalytics } from './Libs/Firebase';
import InteractionWaiter from './Views/Components/InteractionWaiter';
import RoutePassListener from './Views/Components/RoutePassListener';

import IsProfileComplete from './Views/Components/IsProfileComplete';
import OverviewProfileScreen from './Views/Profile/OverviewProfileScreen';
import DocumentsScreen from './Views/Documents/DocumentsScreen';
import PendingJobScreen from './Views/Jobs/Pending/PendingJobsScreen';
import GreetingScreen from './Views/Public/GreetingScreen';
import LoginScreen from './Views/Public/LoginScreen';
import CompleteProfileScreen from './Views/CompleteProfile/CompleteProfileScreen';
import TrophyScreen from './Views/Profile/TrophyScreen';
import JobApplyScreen from './Views/Jobs/JobApplyScreen';
import JobPreviewScreen from './Views/Jobs/Preview/JobPreviewScreen';
import JobSwiperScreen from './Views/Jobs/JobSwiper/JobSwiperScreen';
import JobCardHistoryScreen from './Views/Jobs/JobSwiper/JobCardHistoryScreen';
import LoadingScreen from './Views/General/LoadingScreen';
import UpdateScreen from './Views/General/UpdateScreen';
import AvailabilityScreen from './Views/Availability/AvailabilityScreen';

import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from './Assets/Fontello/line-awesome-config.json';
import Header from './Views/Components/Header';
import SecureStorage from './Libs/SecureStorage';
import APIAction from './Actions/APIAction';
import UserAction from './Actions/UserAction';
import ChildSkillsScreen from './Views/Profile/ChildSkillsScreen';
import IdCardScreen from './Views/CompleteProfile/Items/IdCardScreen';
import BankDetailsScreen from './Views/CompleteProfile/Items/BankDetailsScreen';
import ApproveJob from './Views/Jobs/Approve/ApproveJob';
import FillInHoursJob from './Views/Jobs/FillInHours/FillInHoursJob';
import { useKeyboard } from './Views/Components/KeyboardHook';
import PersonalInfoScreen from './Views/CompleteProfile/Items/PersonalInfoScreen';
import PersonalInfoScreen2 from './Views/CompleteProfile/Items/PersonalInfoScreen2';
import AdditionalInfoScreen from './Views/CompleteProfile/Items/AdditionalInfoScreen';
import ResetPasswordRequestScreen from './Views/Public/ResetPasswordRequestScreen';
import ResetPasswordScreen from './Views/Public/ResetPasswordScreen';
import SignDocumentsScreen from './Views/CompleteProfile/Items/SignDocumentsScreen';
import UpdateChecker from './Libs/UpdateChecker';
import { useRecoilState } from 'recoil';
import RouteState from './Recoil/RouteState';
import JobCountState from './Recoil/JobCountState';
import JobPromoAction from './Actions/JobPromoAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const linking = {
    prefixes: [
        'http://localhost:3000',
        'https://employee.silvertie.aware.be',
        'https://staging.employee.silvertie.aware.be'
    ],
    config: {
        screens: {
            Loading: 'loading',
            Greeting: 'greeting',
            Login: 'login',
            ResetPasswordRequest: 'resetPasswordRequest',
            ResetPassword: 'resetPassword',
            App: '',
            Main: 'main',
            Public: 'public',
            Start: 'start',
            Jobs: 'jobs',
            Documents: 'documents',
            Settings: 'settings',
            Pending: 'pending',
            CompleteProfile: 'complete_profile',
            OverviewProfile: 'overview_profile',
            Trophy: 'trophy',
            Apply: 'apply/:id',
            Preview: 'preview/:id',
            Profile: 'profile',
            ChildSkills: 'childSkills',
            ApproveJob: 'approveJob',
            FillInHoursJob: 'fillInHoursJob',
            Waiting: 'waiting',
            CardHistory: 'card_history',
            Availability: 'availability',
        },
    },
};

const TabBarIcon = ({ color, focused, size, iconName }) => {

    const keyboardSize = useKeyboard();

    let FocusedBox = (props) => {

        return (
            <Box
                size={70}
                bg={'primary.600'}
                style={{
                    alignSelf: 'center',
                    position: 'absolute',
                    top: (keyboardSize === 0 || Platform.OS === 'ios' ? -25 : 0),
                    borderRadius: 35,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.children}
            </Box>
        );
    };

    let tabIcon = <Icon name={iconName} size={30} color={color} />;

    if (focused) {
        return (
            <FocusedBox>
                {tabIcon}
            </FocusedBox>
        );
    }

    return (
        <Box style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50 }}>
            {tabIcon}
        </Box>
    );
};

const JobRouter = ({ navigation }) => {

    const prevFocused = useRef(false);
    const focused = useIsFocused();
    let [routePass, setRoutePass] = useRecoilState(RouteState);

    useEffect(() => {
        if (prevFocused.current === false && focused === true && !routePass) {
            navigation.navigate('Swiper');
        }
        prevFocused.current = focused;
    }, [focused, navigation, routePass]);

    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'Swiper'}
            >
                <Stack.Screen name={'Swiper'} component={JobSwiperScreen} />
                <Stack.Screen name={'Apply'} component={JobApplyScreen} />
                <Stack.Screen name={'CardHistory'} component={JobCardHistoryScreen} />
                <Stack.Screen name={'Availability'} component={AvailabilityScreen} />
            </Stack.Navigator>
            <RoutePassListener onTrigger={(link) => {
                if (link) {
                    // Get parameters from url
                    let paramString = link.split('?');

                    if (paramString.length >= 2) {
                        // Get parameters in an array
                        paramString = paramString[1];
                        let queryString = new URLSearchParams(paramString);

                        if (link.includes('open_shift') && queryString.has('employeeShiftId')) {
                            // Open job
                            navigation.navigate('Preview', { id: queryString.get('employeeShiftId') });
                            setRoutePass(false);
                        }

                        if (link.includes('job_requests') && queryString.has('jobPromoId')) {

                            // Link props
                            let linkProps = {
                                id: queryString.get('jobPromoId'),
                            }

                            if (queryString.has('t')) {
                                linkProps['trackingCode'] = queryString.get('t')
                            }

                            // Open job
                            navigation.navigate('Apply', linkProps);
                            setRoutePass(false);
                        }
                    }

                    else if (link.includes('job_requests')) {
                        navigation.navigate('Swiper');
                        setRoutePass(false);
                    }
                }
            }} />
        </InteractionWaiter>
    );
};

const TrophyRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'Trophy'}
            >
                <Stack.Screen name={'Trophy'} component={TrophyScreen} />
                <Stack.Screen name={'ApproveJob'} component={ApproveJob} />
                <Stack.Screen name={'FillInHoursJob'} component={FillInHoursJob} />
                <Stack.Screen name={'Preview'} component={JobPreviewScreen} />
                <Stack.Screen name={'Availability'} component={AvailabilityScreen} />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};

const PendingJobRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'Pending'}
            >
                <Stack.Screen name={'Pending'} component={PendingJobScreen} />
                <Stack.Screen name={'Preview'} component={JobPreviewScreen} />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};

const ProfileRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'OverviewProfile'}
            >
                <Stack.Screen name={'OverviewProfile'} component={OverviewProfileScreen} />
                <Stack.Screen name={'ChildSkills'} component={ChildSkillsScreen} />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};

const TabRouter = ({ navigation }) => {

    let [ready, setReady] = useState(false);
    let [payrolling, setPayRolling] = useState(false);
    let [jobCount, setJobCount] = useRecoilState(JobCountState);

    const updateJobs = useCallback(() => {
        const init = async () => {
            await JobPromoAction.sync();
            let unseen = await JobPromoAction.getUnseenCount();
            setJobCount(unseen);
        };
        init();
    }, [setJobCount]);

    useEffect(() => {
        const onStart = async () => {
            // Get if user is payroller or not
            let user = await UserAction.getUser();

            updateJobs();

            setReady(true);
            setPayRolling(user.employee.payrolling);
        };

        if (!ready) {
            onStart();
        }
    }, [ready, updateJobs]);

    if (ready) {
        return (
            <>
                <InteractionWaiter>
                    <Header />
                    <Tab.Navigator
                        screenOptions={{
                            headerShown: false,
                            tabBarShowLabel: false,
                            tabBarActiveTintColor: '#fff',
                            tabBarStyle: {
                                position: 'absolute',
                                height: Platform.OS === 'ios' ? 80 : 65,
                                paddingTop: 5,
                                paddingBottom: Platform.OS === 'ios' ? 25 : 10,
                            },
                        }}
                    >
                        <Tab.Screen name={'Home'} component={TrophyRouter}
                            options={{ tabBarIcon: (props) => <TabBarIcon {...props} iconName={'home'} /> }} />
                        {/*<Tab.Screen name={'PendingJobs'} component={PendingJobRouter}
                            options={{
                                tabBarIcon: (props) => <TabBarIcon {...props} iconName={'calendar-check'} />,
                            }} />*/}
                        <Tab.Screen name={'Jobs'} component={JobRouter} options={{ tabBarBadgeStyle: {fontSize: 12, minWidth: 24, height: 24, borderRadius: 12, paddingTop: 3 }, tabBarBadge: jobCount, tabBarIcon: (props) => <TabBarIcon {...props} iconName={'briefcase'} /> }} />
                        {/*<Tab.Screen name={'Documents'} component={DocumentsScreen}
            options={{
              tabBarIcon: (props) => <TabBarIcon {...props} iconName={'alternate-file'} />,
            }} />*/}
                        <Tab.Screen name={'Profile'} component={ProfileRouter}
                            options={{ tabBarIcon: (props) => <TabBarIcon {...props} iconName={'user'} /> }} />
                    </Tab.Navigator>
                    <RoutePassListener onTrigger={(link) => {
                        if (link) {
                            if (link.includes('job_requests') || link.includes('open_shift')) {
                                navigation.navigate('Jobs');
                            }
                        }
                    }} />
                </InteractionWaiter>
            </>
        );
    } else {
        return (<></>);
    }

};

const CompleteProfileRouter = () => {
    return (
        <>
            <InteractionWaiter>
                <Header />
                <Stack.Navigator
                    screenOptions={{
                        tabBarHideOnKeyboard: Platform.OS === 'android',
                        headerShown: false,
                    }}
                >
                    <Stack.Screen name={'CompleteProfile'} component={CompleteProfileScreen} />
                    <Stack.Screen name={'CompletePersonalInfo'} component={PersonalInfoScreen2} />
                    <Stack.Screen name={'CompleteProfileID'} component={IdCardScreen} />
                    <Stack.Screen name={'CompleteBankDetails'} component={BankDetailsScreen} />
                    <Stack.Screen name={'CompleteAdditionalInfo'} component={AdditionalInfoScreen} />
                    <Stack.Screen name={'SignDocuments'} component={SignDocumentsScreen} />
                </Stack.Navigator>
            </InteractionWaiter>
        </>
    );
};

const MainRouter = () => {
    return (
        <>
            <InteractionWaiter>
                <Stack.Navigator
                    screenOptions={{
                        headerShown: false,
                    }}
                    initialRouteName={'Waiting'}
                >
                    <Stack.Screen name={'Waiting'} component={IsProfileComplete} />
                    <Stack.Screen name={'App'} component={TabRouter} />
                    <Stack.Screen name={'Start'} component={CompleteProfileRouter} />
                    <Stack.Screen name={'Settings'} component={CompleteProfileRouter} />
                </Stack.Navigator>
                <MessagingHandler />
            </InteractionWaiter>
        </>
    );
};

const PublicRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'Greeting'}
            >
                <Stack.Screen name={'Greeting'} component={GreetingScreen} />
                <Stack.Screen name={'Login'} component={LoginScreen} />
                <Stack.Screen name={'ResetPasswordRequest'} component={ResetPasswordRequestScreen} />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};

const RootRouter = (props) => {

    const routeNameRef = React.useRef();
    const navigationRef = React.useRef();
    let [routePass, setRoutePass] = useRecoilState(RouteState);

    return (
        <>
            <NavigationContainer
                documentTitle={{
                    enabled: false
                }}
                linking={linking}
                fallback={<LoadingScreen />}
                ref={navigationRef}
                onReady={() => {
                    routeNameRef.current = navigationRef.current.getCurrentRoute().name;
                }}
                onStateChange={async () => {
                    const previousRouteName = routeNameRef.current;
                    const currentRouteName = navigationRef.current.getCurrentRoute().name;

                    if (previousRouteName !== currentRouteName) {
                        await logAnalytics({
                            screen_name: currentRouteName,
                            screen_class: currentRouteName,
                        });
                    }

                    routeNameRef.current = currentRouteName;
                }}
            >
                <Stack.Navigator
                    screenOptions={{
                        headerShown: false,
                    }}
                    initialRouteName={props.mainRoute}
                >
                    <Stack.Screen name={'Public'} component={PublicRouter} />
                    <Stack.Screen name={'Main'} component={MainRouter} />
                    <Stack.Screen name={'ResetPassword'} component={ResetPasswordScreen} />
                    <Stack.Screen name={'Update'} component={UpdateScreen} />
                </Stack.Navigator>
                <UpdateChecker />
            </NavigationContainer>
            <LinkHandler onTrigger={(link) => {
                // Check if we have a link
                if (link) {
                    // Get parameters from url
                    let paramString = link.split('?');

                    if (paramString.length >= 2) {
                        // Get parameters in an array
                        paramString = paramString[1];
                        let queryString = new URLSearchParams(paramString);

                        // Reset password page
                        if (link.includes('resetpassword') && queryString.has('secret')) {
                            navigationRef.current?.navigate('ResetPassword', { secret: queryString.get('secret') });
                        }

                        // Impersonate user
                        if (link.includes('impersonate') && queryString.has('user')) {
                            APIAction.impersonate(queryString.get('user'));
                        }

                        // Open job
                        if (link.includes('open_shift') && queryString.has('employeeShiftId')) {
                            setRoutePass(link);
                        }
                    }

                    if (link.includes('job_requests')) {
                        setRoutePass(link);
                    }
                }
            }} />
        </>
    );
};

const Router = (props) => {
    let [ready, setReady] = useState(false);
    let [mainRoute, setMainRoute] = useState('Public');

    useEffect(() => {
        const onStart = async () => {

            let authorized = await APIAction.authenticationCheck();

            if (!authorized) {
                let credentials = await SecureStorage.getCredentials();
                if (credentials) {
                    let loginResult = await APIAction.login(credentials.username, credentials.password);
                    if (loginResult) {
                        setMainRoute('Main');
                    }
                }
            } else {
                setMainRoute('Main');
            }

            //load main router
            setReady(true);
        };
        if (!ready) {
            onStart();
        }
    }, [ready]);

    //render
    if (ready) {
        return <RootRouter {...props} mainRoute={mainRoute} />;
    } else {
        return <LoadingScreen />;
    }
};

export default Router;

import React, { useEffect } from "react";
import GeneralAction from "../../Actions/GeneralAction";

const ShareMessage = ({ title, text, url, onDone }) => {

    const onShare = useEffect(() => {
        const init = async () => {
            //put url in clipboard
            navigator.clipboard.writeText(text + '\n' + url);
            //let user know
            GeneralAction.toast('success', 'Link copied to clipboard');

            if (typeof onDone === 'function') {
                onDone();
            }
        };
        init();
    }, [title, text, url, onDone]);

    return <></>;
};

export default ShareMessage;